function requestYads() {
  window.yadsRequestAsync({
    yads_ad_ds: "93111_258558",
    yads_parent_element: "yads",
  });
}

export default function adsYahoo() {
  const yads = window.document.createElement("script");
  yads.type = "text/javascript";
  yads.src = "//yads.c.yimg.jp/js/yads-async.js";
  yads.async = true;
  if (yads.addEventListener) {
    yads.onload = requestYads;
  } else {
    yads.onreadystatechange = () => {
      if (yads.readyState === "loaded" || yads.readyState === "complete") {
        yads.onreadystatechange = null;
        requestYads();
      }
    };
  }
  window.document.body.appendChild(yads);
  document.getElementById("yads").style.display = "block";
}

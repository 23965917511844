import findAdsRoute from "../utils/findAdsRoute";
import isSmartphone from "../utils/isSmartPhone";
import getCurrentRoute from "../utils/getCurrentRoute";
import { ADS_PROMOTION_UNIT_NAME } from "../utils/constants";
import adsYahoo from "./adsYahoo";
import Url from "url";

export default function initAds() {
  const urlParams = Url.parse(window.location.href, true).query;
  if (urlParams.ads === "kill"
    || navigator.userAgent.toLowerCase().indexOf('googlebot') > -1
    || navigator.userAgent.toLowerCase().indexOf('lighthouse') > -1) {
    const mpRecommends = document.querySelectorAll('.mp-recommends');
    mpRecommends.forEach(mpRecommendsItem => {
      mpRecommendsItem.style.display = 'none';
    })
    const adsList = document.querySelectorAll('.ads-img-screen');
    adsList.forEach(adsItem => {
      adsItem.style.display = 'none';
    })
    return;
  }

  const article_ad = document.querySelector('article.article');
  const ad_unit = article_ad ? article_ad.getAttribute("data-ad-unit") : null;
  const ad_option = article_ad ? article_ad.getAttribute("data-ad-option") : null;
  const adsRoute = findAdsRoute(getCurrentRoute());
  var adsItems = document.querySelectorAll("[data-ads]");

  console.log(`position:${ad_unit}`);
  console.log(`opt:${ad_option}`);

  Array.prototype.forEach.call(adsItems, function (ads) {
    var position = ads.getAttribute("data-ads-position");

    if (position === ADS_PROMOTION_UNIT_NAME && ad_option) {
      var adsId = ad_option;
    } else {
      var adsId = adsRoute[position].opt;
    }

    ads.querySelectorAll("[data-ads-id]")[0].id = adsId;
  });

  var sequenceAdsCall = [
    "fixed",
    "screen01",
    "screen02",
    "screen03",
    "aside01",
    "aside02",
    "aside03",
    "consult",
  ];

  var defaultSizePc = [
    [300, 250],
    [336, 280],
  ];

  var defaultSizeSp = [
    [300, 250],
    [320, 50],
    [336, 280],
    [728, 90],
    [468, 60],
  ];

  var defaultSize = isSmartphone() ? defaultSizeSp : defaultSizePc;

  window.googletag.cmd.push(() => {
    sequenceAdsCall.map((s) => {
      if (!adsRoute[s]) return false;
      const r = adsRoute[s];
      var unit = (s === ADS_PROMOTION_UNIT_NAME && ad_unit) ? ad_unit : r.unit;
      var opt = (s === ADS_PROMOTION_UNIT_NAME && ad_option) ? ad_option : r.opt;
      if (window.googletag[`__mpt_${s}`]) {
        window.googletag.destroySlots([window.googletag[`__mpt_${s}`]]);
      }
      if (r.unit && r.opt) {
        window.googletag[`__mpt_${s}`] = window.googletag.defineSlot(unit, (r.size || defaultSize), opt);
        window.googletag[`__mpt_${s}`].addService(window.googletag.pubads());
      }
      return false;
    });
    window.googletag.pubads().disableInitialLoad();
    window.googletag.pubads().enableSingleRequest();
    window.googletag.enableServices();
  });

  const slots = [];
  sequenceAdsCall.map((s) => {
    if (!adsRoute[s]) return false;
    const r = adsRoute[s];
    var unit = (s === ADS_PROMOTION_UNIT_NAME && ad_unit) ? ad_unit : r.unit;
    var opt = (s === ADS_PROMOTION_UNIT_NAME && ad_option) ? ad_option : r.opt;
    if (!r.size) {
      slots.push({
        slotID: opt,
        slotName: unit,
        sizes: (r.size || defaultSize),
      });
    }
    return false;
  });

  window.apstag.fetchBids({
    slots,
    timeout: 2e3,
  }, () => {
    window.googletag.cmd.push(() => {
      window.apstag.setDisplayBids();
      window.googletag.pubads().refresh();
    });
  });

  sequenceAdsCall.map((s) => {
    if (!adsRoute[s]) return false;
    const r = adsRoute[s];
    window.googletag.cmd.push(() => window.googletag.display(r.opt));
    return false;
  });

  if (urlParams.utm_source === "yahoo") {
    adsYahoo();
  }
}

initAds();

import isSmartphone from './isSmartPhone';

function getAdsRoutePC(routeName) {
  const adsRoute = {
    fixed: { unit: '/76346382/header_pc_ad_00', opt: 'div-gpt-ad-1585893993855-0', size: [728, 90] },
    consult: { unit: '/76346382/single_sp_house_02', opt: 'div-gpt-ad-1552967771239-0', size: [320, 100] },
  };

  switch (routeName) {
    case 'ArticlesHome':
      adsRoute.fixed = { unit: '', opt: '' };
      adsRoute.aside01 = { unit: '/76346382/home_pc_ad_01', opt: 'div-gpt-ad-1474440125884-0' };
      adsRoute.aside02 = { unit: '/76346382/home_pc_ad_02', opt: 'div-gpt-ad-1474449675219-0' };
      adsRoute.aside03 = { unit: '/76346382/home_pc_ad_03', opt: 'div-gpt-ad-1474450059683-0' };
      break;
    case 'SeriesIndex':
      adsRoute.aside01 = { unit: '/76346382/special_pc_ad_01', opt: 'div-gpt-ad-1516355569517-0' };
      adsRoute.aside02 = { unit: '/76346382/special_pc_ad_02', opt: 'div-gpt-ad-1474452104898-0' };
      adsRoute.aside03 = { unit: '/76346382/special_pc_ad_03', opt: 'div-gpt-ad-1474452302663-0' };
      break;
    case 'SeriesShow':
      adsRoute.aside01 = { unit: '/76346382/special_pc_ad_01', opt: 'div-gpt-ad-1516355569517-0' };
      adsRoute.aside02 = { unit: '/76346382/special_pc_ad_02', opt: 'div-gpt-ad-1474452104898-0' };
      adsRoute.aside03 = { unit: '/76346382/special_pc_ad_03', opt: 'div-gpt-ad-1474452302663-0' };
      break;
    case 'AuthorsIndex':
      adsRoute.aside01 = { unit: '/76346382/author_pc_ad_01', opt: 'div-gpt-ad-1474458417594-0' };
      adsRoute.aside02 = { unit: '/76346382/author_pc_ad_02', opt: 'div-gpt-ad-1474458578809-0' };
      adsRoute.aside03 = { unit: '/76346382/author_pc_ad_03', opt: 'div-gpt-ad-1474458623319-0' };
      break;
    case 'AuthorsShow':
      adsRoute.aside01 = { unit: '/76346382/author_pc_ad_01', opt: 'div-gpt-ad-1474458417594-0' };
      adsRoute.aside02 = { unit: '/76346382/author_pc_ad_02', opt: 'div-gpt-ad-1474458578809-0' };
      adsRoute.aside03 = { unit: '/76346382/author_pc_ad_03', opt: 'div-gpt-ad-1474458623319-0' };
      break;
    case 'PhotographersIndex':
      adsRoute.aside01 = { unit: '/76346382/author_pc_ad_01', opt: 'div-gpt-ad-1474458417594-0' };
      adsRoute.aside02 = { unit: '/76346382/author_pc_ad_02', opt: 'div-gpt-ad-1474458578809-0' };
      adsRoute.aside03 = { unit: '/76346382/author_pc_ad_03', opt: 'div-gpt-ad-1474458623319-0' };
      break;
    case 'ArticlesIndex':
      adsRoute.aside01 = { unit: '/76346382/newpost_pc_ad_01', opt: 'div-gpt-ad-1474451168127-0' };
      adsRoute.aside02 = { unit: '/76346382/newpost_pc_ad_02', opt: 'div-gpt-ad-1474451244650-0' };
      adsRoute.aside03 = { unit: '/76346382/newpost_pc_ad_03', opt: 'div-gpt-ad-1474451350038-0' };
      break;
    case 'ArticlesShow':
      adsRoute.aside01 = { unit: '/76346382/single_pc_ad_01', opt: 'div-gpt-ad-1506160475625-0' };
      adsRoute.aside02 = { unit: '/76346382/single_pc_ad_02', opt: 'div-gpt-ad-1474452471535-0' };
      adsRoute.aside03 = { unit: '/76346382/single_pc_ad_03', opt: 'div-gpt-ad-1474452538346-0' };
      adsRoute.screen01 = { unit: '/76346382/single_pc_ad_00', opt: 'div-gpt-ad-1548925854010-0' };
      adsRoute.screen02 = { unit: '/76346382/single_pc_ad_05', opt: 'div-gpt-ad-1582883375589-0', size: [468, 60] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
      break;
    case 'ArticlesPreview':
      adsRoute.aside01 = { unit: '/76346382/single_pc_ad_01', opt: 'div-gpt-ad-1506160475625-0' };
      adsRoute.aside02 = { unit: '/76346382/single_pc_ad_02', opt: 'div-gpt-ad-1474452471535-0' };
      adsRoute.aside03 = { unit: '/76346382/single_pc_ad_03', opt: 'div-gpt-ad-1474452538346-0' };
      adsRoute.screen01 = { unit: '/76346382/single_pc_ad_00', opt: 'div-gpt-ad-1548925854010-0' };
      adsRoute.screen02 = { unit: '/76346382/single_pc_ad_05', opt: 'div-gpt-ad-1582883375589-0', size: [468, 60] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
      break;
    case 'ArticlesSummary':
      adsRoute.aside01 = { unit: '/76346382/summary_pc_ad_01', opt: 'div-gpt-ad-1577256419874-0' };
      adsRoute.aside02 = { unit: '/76346382/summary_pc_ad_02', opt: 'div-gpt-ad-1577256690108-0' };
      adsRoute.aside03 = { unit: '/76346382/summary_pc_ad_03', opt: 'div-gpt-ad-1577256776111-0' };
      adsRoute.screen01 = { unit: '/76346382/summary_pc_ad_00', opt: 'div-gpt-ad-1577256322355-0' };
      break;
    case 'ArticlesPromoted':
      adsRoute.aside01 = { unit: '/76346382/pr_pc_ad_01', opt: 'div-gpt-ad-1474451463211-0' };
      adsRoute.aside02 = { unit: '/76346382/pr_pc_ad_02', opt: 'div-gpt-ad-1474451539617-0' };
      adsRoute.aside03 = { unit: '/76346382/pr_pc_ad_03', opt: 'div-gpt-ad-1474451618303-0' };
      break;
    case 'GalleriesIndex':
      adsRoute.aside01 = { unit: '/76346382/galleries_pc_ad_01', opt: 'div-gpt-ad-1577255095655-0' };
      adsRoute.aside02 = { unit: '/76346382/galleries_pc_ad_02', opt: 'div-gpt-ad-1577255404097-0' };
      adsRoute.aside03 = { unit: '/76346382/galleries_pc_ad_03', opt: 'div-gpt-ad-1577255528575-0' };
      break;
    case 'GalleriesShow':
      adsRoute.aside01 = { unit: '/76346382/galleries_pc_ad_01', opt: 'div-gpt-ad-1577255095655-0' };
      adsRoute.aside02 = { unit: '/76346382/galleries_pc_ad_02', opt: 'div-gpt-ad-1577255404097-0' };
      adsRoute.aside03 = { unit: '/76346382/galleries_pc_ad_03', opt: 'div-gpt-ad-1577255528575-0' };
      adsRoute.screen01 = { unit: '', opt: '' };
      break;
    case 'CategoriesShow':
      adsRoute.aside01 = { unit: '/76346382/category_pc_ad_01', opt: 'div-gpt-ad-1474450137233-0' };
      adsRoute.aside02 = { unit: '/76346382/category_pc_ad_02', opt: 'div-gpt-ad-1474450206825-0' };
      adsRoute.aside03 = { unit: '/76346382/category_pc_ad_03', opt: 'div-gpt-ad-1474450501561-0' };
      break;
    default:
      adsRoute.aside01 = { unit: '/76346382/single_pc_ad_01', opt: 'div-gpt-ad-1506160475625-0' };
      adsRoute.aside02 = { unit: '/76346382/single_pc_ad_02', opt: 'div-gpt-ad-1474452471535-0' };
      adsRoute.aside03 = { unit: '/76346382/single_pc_ad_03', opt: 'div-gpt-ad-1474452538346-0' };
      adsRoute.screen01 = { unit: '/76346382/single_pc_ad_00', opt: 'div-gpt-ad-1548925854010-0' };
      adsRoute.screen02 = { unit: '/76346382/single_pc_ad_05', opt: 'div-gpt-ad-1582883375589-0', size: [468, 60] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
  }
  return adsRoute;
}

function getAdsRouteSP(routeName) {
  const adsRoute = {
    fixed: { unit: '/76346382/footer_sp_ad_00', opt: 'div-gpt-ad-1583996390256-0', size: [320, 50] },
    consult: { unit: '/76346382/single_sp_house_02', opt: 'div-gpt-ad-1552967771239-0', size: [320, 100] },
  };
  switch (routeName) {
    case 'ArticlesHome':
      adsRoute.fixed = { unit: '', opt: '' };
      adsRoute.aside01 = { unit: '/76346382/home_sp_ad_01', opt: 'div-gpt-ad-1511754157093-0' };
      adsRoute.aside02 = { unit: '/76346382/home_sp_ad_02', opt: 'div-gpt-ad-1511754297200-0' };
      adsRoute.aside03 = { unit: '/76346382/home_sp_ad_03', opt: 'div-gpt-ad-1511754326513-0' };
      break;
    case 'SeriesIndex':
      adsRoute.aside01 = { unit: '/76346382/special_sp_ad_01', opt: 'div-gpt-ad-1511761921357-0' };
      adsRoute.aside02 = { unit: '/76346382/special_sp_ad_02', opt: 'div-gpt-ad-1511762086269-0' };
      adsRoute.aside03 = { unit: '/76346382/special_sp_ad_03', opt: 'div-gpt-ad-1540195270377-0' };
      break;
    case 'SeriesShow':
      adsRoute.aside01 = { unit: '/76346382/special_sp_ad_01', opt: 'div-gpt-ad-1511761921357-0' };
      adsRoute.aside02 = { unit: '/76346382/special_sp_ad_02', opt: 'div-gpt-ad-1511762086269-0' };
      adsRoute.aside03 = { unit: '/76346382/special_sp_ad_03', opt: 'div-gpt-ad-1540195270377-0' };
      break;
    case 'AuthorsIndex':
      adsRoute.aside01 = { unit: '/76346382/author_sp_ad_01', opt: 'div-gpt-ad-1511761350850-0' };
      adsRoute.aside02 = { unit: '/76346382/author_sp_ad_02', opt: 'div-gpt-ad-1511761409321-0' };
      adsRoute.aside03 = { unit: '/76346382/author_sp_ad_03', opt: 'div-gpt-ad-1540194172206-0' };
      break;
    case 'AuthorsShow':
      adsRoute.aside01 = { unit: '/76346382/author_sp_ad_01', opt: 'div-gpt-ad-1511761350850-0' };
      adsRoute.aside02 = { unit: '/76346382/author_sp_ad_02', opt: 'div-gpt-ad-1511761409321-0' };
      adsRoute.aside03 = { unit: '/76346382/author_sp_ad_03', opt: 'div-gpt-ad-1540194172206-0' };
      break;
    case 'PhotographersIndex':
      adsRoute.aside01 = { unit: '/76346382/author_sp_ad_01', opt: 'div-gpt-ad-1511761350850-0' };
      adsRoute.aside02 = { unit: '/76346382/author_sp_ad_02', opt: 'div-gpt-ad-1511761409321-0' };
      adsRoute.aside03 = { unit: '/76346382/author_sp_ad_03', opt: 'div-gpt-ad-1540194172206-0' };
      break;
    case 'ArticlesIndex':
      adsRoute.aside01 = { unit: '/76346382/newpost_sp_ad_01', opt: 'div-gpt-ad-1511761237759-0' };
      adsRoute.aside02 = { unit: '/76346382/newpost_sp_ad_02', opt: 'div-gpt-ad-1511761300195-0' };
      adsRoute.aside03 = { unit: '/76346382/newpost_sp_ad_03', opt: 'div-gpt-ad-1540194621384-0' };
      break;
    case 'ArticlesShow':
      adsRoute.aside01 = { unit: '/76346382/single_sp_ad_01', opt: 'div-gpt-ad-1511761830032-0' };
      adsRoute.aside02 = { unit: '/76346382/single_sp_ad_02', opt: 'div-gpt-ad-1511761864154-0' };
      adsRoute.aside03 = { unit: '/76346382/single_sp_ad_03', opt: 'div-gpt-ad-1540195119877-0' };
      adsRoute.screen01 = { unit: '/76346382/single_sp_ad_00', opt: 'div-gpt-ad-1548925957164-0' };
      adsRoute.screen02 = { unit: '/76346382/single_sp_ad_05', opt: 'div-gpt-ad-1582883883764-0', size: [320, 50] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
      break;
    case 'ArticlesPreview':
      adsRoute.aside01 = { unit: '/76346382/single_sp_ad_01', opt: 'div-gpt-ad-1511761830032-0' };
      adsRoute.aside02 = { unit: '/76346382/single_sp_ad_02', opt: 'div-gpt-ad-1511761864154-0' };
      adsRoute.aside03 = { unit: '/76346382/single_sp_ad_03', opt: 'div-gpt-ad-1540195119877-0' };
      adsRoute.screen01 = { unit: '/76346382/single_sp_ad_00', opt: 'div-gpt-ad-1548925957164-0' };
      adsRoute.screen02 = { unit: '/76346382/single_sp_ad_05', opt: 'div-gpt-ad-1582883883764-0', size: [320, 50] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
      break;
    case 'ArticlesSummary':
      adsRoute.aside01 = { unit: '/76346382/summary_sp_ad_01', opt: 'div-gpt-ad-1577257018134-0' };
      adsRoute.aside02 = { unit: '/76346382/summary_sp_ad_02', opt: 'div-gpt-ad-1577257615238-0' };
      adsRoute.aside03 = { unit: '/76346382/summary_sp_ad_03', opt: 'div-gpt-ad-1577257679950-0' };
      adsRoute.screen01 = { unit: '/76346382/summary_sp_ad_00', opt: 'div-gpt-ad-1577256901012-0' };
      break;
    case 'ArticlesPromoted':
      adsRoute.aside01 = { unit: '/76346382/pr_sp_ad_01', opt: 'div-gpt-ad-1511761761562-0' };
      adsRoute.aside02 = { unit: '/76346382/pr_sp_ad_02', opt: 'div-gpt-ad-1511761783729-0' };
      adsRoute.aside03 = { unit: '/76346382/pr_sp_ad_03', opt: 'div-gpt-ad-1540194908536-0' };
      break;
    case 'GalleriesIndex':
      adsRoute.aside01 = { unit: '/76346382/galleries_sp_ad_01', opt: 'div-gpt-ad-1577255984192-0' };
      adsRoute.aside02 = { unit: '/76346382/galleries_sp_ad_02', opt: 'div-gpt-ad-1577256055359-0' };
      adsRoute.aside03 = { unit: '/76346382/galleries_sp_ad_03', opt: 'div-gpt-ad-1577256135530-0' };
      break;
    case 'GalleriesShow':
      adsRoute.aside01 = { unit: '/76346382/galleries_sp_ad_01', opt: 'div-gpt-ad-1577255984192-0' };
      adsRoute.aside02 = { unit: '/76346382/galleries_sp_ad_02', opt: 'div-gpt-ad-1577256055359-0' };
      adsRoute.aside03 = { unit: '/76346382/galleries_sp_ad_03', opt: 'div-gpt-ad-1577256135530-0' };
      adsRoute.screen01 = { unit: '/76346382/galleries_sp_ad_00', opt: 'div-gpt-ad-1588934439392-0', size: [320, 50] };
      break;
    case 'CategoriesShow':
      adsRoute.aside01 = { unit: '/76346382/category_sp_ad_01', opt: 'div-gpt-ad-1511753990486-0' };
      adsRoute.aside02 = { unit: '/76346382/category_sp_ad_02', opt: 'div-gpt-ad-1511754119702-0' };
      adsRoute.aside03 = { unit: '/76346382/category_sp_ad_03', opt: 'div-gpt-ad-1540194489786-0' };
      break;
    default:
      adsRoute.aside01 = { unit: '/76346382/single_sp_ad_01', opt: 'div-gpt-ad-1511761830032-0' };
      adsRoute.aside02 = { unit: '/76346382/single_sp_ad_02', opt: 'div-gpt-ad-1511761864154-0' };
      adsRoute.aside03 = { unit: '/76346382/single_sp_ad_03', opt: 'div-gpt-ad-1540195119877-0' };
      adsRoute.screen01 = { unit: '/76346382/single_sp_ad_00', opt: 'div-gpt-ad-1548925957164-0' };
      adsRoute.screen02 = { unit: '/76346382/single_sp_ad_05', opt: 'div-gpt-ad-1582883883764-0', size: [320, 50] };
      adsRoute.screen03 = { unit: '/76346382/single_pc_house_01', opt: 'div-gpt-ad-1548038003611-0', size: [320, 100] };
  }
  return adsRoute;
}
/*
 * csr時しか呼ばない想定。windowサイズを取得している。
 */
export default function findAdsRoute(routeName) {
  return isSmartphone() ? getAdsRouteSP(routeName) : getAdsRoutePC(routeName);
}

export const PAGINATOR_NUM_MAX = 5; // 表示最大数
export const PAGINATOR_NUM_RANGE = 2; // 表示幅
export const ARTICLE_DATE_NEW_ICON = 7; // NEWアイコンを表示する日数
export const BREAK_POINT_PC = 960; // PC, Tablet間のブレイクポイント
export const ADS_PROMOTION_UNIT_NAME = 'aside01'; // 記事広告に使用する広告枠の名前
export const FETCH_NTH_TRY = 5; // fetchの繰り返し処理時の試行回数。
export const FETCH_DERAY_TIME = 2000; // fetchの繰り返し処理時の試行間隔ms。

const constants = {
  PAGINATOR_NUM_MAX,
  PAGINATOR_NUM_RANGE,
  ARTICLE_DATE_NEW_ICON,
  BREAK_POINT_PC,
  ADS_PROMOTION_UNIT_NAME,
  FETCH_NTH_TRY,
  FETCH_DERAY_TIME,
};

export default constants;
